import { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import ToggleButton from '@rio-cloud/rio-uikit/lib/es/ToggleButton';

type ButtonWithLoadingInfoProps = {
    isLoading: boolean;
    disabled?: boolean;
    onClick?: Function;
    textKey?: string;
    contentNode?: ReactNode;
};

const ButtonWithLoadingInfo = ({ isLoading, disabled, onClick, textKey, contentNode }: ButtonWithLoadingInfoProps) => (
    <ToggleButton
        className={`btn-primary ${isLoading ? 'btn-loading-overlay' : ''}`}
        disabled={disabled}
        onClick={onClick}
    >
        <span className={isLoading ? 'invisible' : ''}>
            {textKey && <FormattedMessage id={textKey} />}
            {contentNode}
        </span>
    </ToggleButton>
);

export default ButtonWithLoadingInfo;
