import NotificationsContainer from '@rio-cloud/rio-uikit/lib/es/NotificationsContainer';
import { Suspense } from 'react';
import { LoadingIndicator } from './LoadingIndicator';
import NotificationSettings from './NotificationSettings';

const MainComponent = () => {
    return (
        <div className={'overflow-hidden display-flex flex-column'}>
            <Suspense fallback={LoadingIndicator}>
                <div className={'overflow-auto padding-left-15 padding-top-15 padding-bottom-25 padding-right-15'}>
                    <NotificationSettings />
                </div>
                <NotificationsContainer />
            </Suspense>
        </div>
    );
};

export default MainComponent;
